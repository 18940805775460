import { makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import BgImage from "./BgImage";

interface PullQuoteProps {
  quote: string;
  backgroundImage: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  marquee: {
    width: "100%",

    position: "relative"
  },
  image: {
    height: "66vh"
  },
  copy: {
    position: "absolute",
    top: "60px",
    display: "inline-block",
    // width: '100%',
    fontSize: "30px",
    background: "rgba(255,255,255,255)",
    padding: "60px",
    color: theme.palette.primary.dark
  }
}));

// Stateless Functional Component / Returns JSX
const MarqueeImage: React.FC<any> = ({ quote, backgroundImage, theme }) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.marquee}>
      <BgImage
        fluid={backgroundImage}
        title={quote}
        className={classes.image}
      />
      <Typography
        gutterBottom
        paragraph
        style={{
          position: "absolute",

          width: "100%",
          // height: '100%',
          top: "60px",
          color: "#FFFFFF",
          textAlign: "left",
          fontSize: 20,
          fontFamily: "Montserrat",
          fontWeight: 300,
          padding: "0px 60px 100px 60px",
          letterSpacing: "1px",
          lineHeight: "60px",
          // background: '',
          backgroundImage:
            "linear-gradient(to top , rgba(20,35,67,0), rgba(20,35,67,1))"
        }}
      >
        {/* {quote} */}
      </Typography>
    </div>
  );
};

export default MarqueeImage;
