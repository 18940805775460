import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import * as React from 'react';

// Attributes You Can Set On Tag
interface FeatureImage {
  title: string;
  path: string;
  header: string;
}

// Applied styles
const useStyles = makeStyles((theme: Theme) => ({
  imgTemplate: {
    height: '100%',
    position: 'relative',
    width: '100%'
  },
  imgText: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    padding: '12px',
    display: 'inline-block',
    color: theme.palette.primary.light,
    background: 'rgba(255, 255, 255, .9)',
    border: `1px solid ${theme.palette.primary.light}`
  }
}));

// Stateless Functional Component / Returns JSX
const FeaturePerson: React.FC<any> = ({
  name,
  institution,
  bio,
  role,
  img,
  theme
}) => {
  const classes = useStyles(theme);
  return (
    <Grid item xs={12} sm={4} md={2}>
      <div className={classes.imgTemplate} style={{ padding: '20px' }}>
        <img
          src={img}
          style={{
            width: '100%'
          }}
        />
        <img
          src={institution}
          style={{
            float: 'right',
            width: '40px'
          }}
        />
        <div>{name}</div>
        <div>{role}</div>
      </div>
    </Grid>
  );
};

export default FeaturePerson;
