import { makeStyles, Theme, Typography, Grid } from "@material-ui/core";
import * as React from "react";


const useStyles = makeStyles((theme: Theme) => ({
    video: {
        backgroundColor: "#F4F4F7",
        objectFit: "fill",
        width: "50%",
        height: "400px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        }
    }
}));

// Stateless Functional Component / Returns JSX
const FeatureVideo: React.FC<any> = ({ fullwidth, theme }) => {
    const classes = useStyles(theme);
    return (
        <video className={classes.video} controls >
            <source src="../img/cole.mp4" type="video/mp4" />
        </video>

    );
};

export default FeatureVideo
;