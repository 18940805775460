import { makeStyles, Theme, Grid } from '@material-ui/core';
import * as React from 'react';
import BgImage from '../layout/promo/BgImage';

// Attributes You Can Set On Tag
interface FeatureImage {
  title: string;
  path: string;
  header: string;
}

// Applied styles
const useStyles = makeStyles((theme: Theme) => ({
  imgTemplate: {
    height: '100%',
    position: 'relative',
    width: '100%'
  },
  imgText: {
    position: 'absolute',
    right: '00px',
    bottom: '0px',
    padding: '12px',
    width: '100%',
    display: 'inline-block',
    color: theme.palette.primary.light,
    background:
      'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, .7), rgba(255, 255, 255, .1))',
    fontSize: '12px'
  }
}));

// Stateless Functional Component / Returns JSX
const FeatureImage: React.FC<any> = ({
  title,
  backgroundImage,
  fullwidth,
  theme
}) => {
  const md = fullwidth ? 12 : 6;
  const classes = useStyles(theme);
  return (
    <Grid item xs={12} sm={12} md={md}>
      <div className={classes.imgTemplate}>
        <BgImage fluid={backgroundImage} title={title} />
        <span className={classes.imgText}>{title}</span>
      </div>
    </Grid>
  );
};

export default FeatureImage;
