import FeatureBullet from "../components/feature/FeatureBullet";
import FeatureCarousel from "../components/feature/FeatureCarousel";
import FeatureCopy from "../components/feature/FeatureCopy";
import FeatureImage from "../components/feature/FeatureImage";
import FeaturePerson from "../components/feature/FeaturePerson";
import FeatureCard from "../components/feature/FeatureCard";
import FeatureRequestForm from "../components/feature/FeatureRequestForm";
import FeatureTwitter from "../components/feature/FeatureTwitter";
import FeatureQuote from '../components/feature/FeatureQuote';
import FeatureVideo from '../components/feature/FeatureVideo';
import Layout from '../components/Layout';
import MarqueeImage from '../components/layout/promo/MarqueeImage';
import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
//import 'swagger-ui-react/swagger-ui.css';
interface IProps {
  title: string;
  image: any;

  components: [
    {
      component: string;
      quote: string;
      cta: string;
      h1: string;
      h2: string;
      p: string;
      img: string;
      fullwidth: boolean;
      a: {
        href: string;
        label: string;
      };
      image: object;
    }
  ];
}

export const FeaturePageTemplate: React.FC<any> = ({
  image,
  components,
  title
}) => {
  const elComponents: any = [];

  for (const [index, value] of components.entries()) {
    switch (value.component) {
      case "feature-person":
        elComponents.push(
          <FeaturePerson
            key={`fp${index}`}
            name={value.name}
            institution={value.institution}
            img={value.img}
            role={value.role}
            bio={value.bio}
          />
        );
        break;
      case "feature-image":
        elComponents.push(
          <FeatureImage
            key={`fi${index}`}
            backgroundImage={value.image.childImageSharp.fluid}
            fullwidth={value.fullwidth ? true : false}
            title={value.title}
          />
        );
        break;
      case "feature-copy":
        elComponents.push(
          <FeatureCopy
            key={`fc${index}`}
            h1={value.h1}
            h2={value.h2}
            p={value.p}
            a={value.a}
            // img={value.img}
            fullwidth={value.fullwidth ? true : false}
          />
        );
        break;
      case "feature-quote":
        elComponents.push(
          <FeatureQuote key={`fq${index}`} quote={value.quote} />
        );
        break;
      case "feature-request":
        elComponents.push(
          <FeatureRequestForm key={`fr${index}`} onSubmit={value.name} />
        );
        break;
      case "feature-card":
          elComponents.push(
            <FeatureCard
              key={`fx${index}`}
              subtitle={value.subtitle}
              title={value.title}
              copy={value.copy}
              link={value.link}
              img={value.img}
            />
        );
          break;
      case "feature-carousel":
            elComponents.push(
              <FeatureCarousel props={JSON.parse(value.props)} key={index} />
        );
          break;
      case "feature-twitter":
            elComponents.push(
              <FeatureTwitter key={`ft${index}`} />
        );
          break;
      case "feature-video":
            elComponents.push(
              <FeatureVideo key={`fv${index}`} />
        );
          break;
      case "feature-bullet":
            elComponents.push(
              <FeatureBullet 
                key={`fb${index}`}
                copy={value.copy}
                title={value.title}
                img={value.img}
                list={value.list}
              />
        );
          break;
    }
  }

  return (
    <div>
      <MarqueeImage
        quote={title}
        backgroundImage={image.childImageSharp.fluid}
      />

      <Grid container spacing={0}>
        {elComponents}
      </Grid>
    </div>
  );
};

const FeaturePage = (props: any) => {
  const { frontmatter, html } = props.data.page;
  return (
    <Layout config={props.data.config.frontmatter}>
      <FeaturePageTemplate
        title={frontmatter.title}
        headline={frontmatter.headline}
        image={frontmatter.image}
        components={frontmatter.components}
      />
    </Layout>
  );
};

export default FeaturePage;

// 'fa20b033-fba4-5eaa-bdff-92227e41e210';

export const pageQuery = graphql`
  query FeaturePage($id: String, $cid: String) {
    config: markdownRemark(id: { eq: $cid }) {
      frontmatter {
        title
        navigation {
          link
          label
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        components {
          component
          quote
          title
          h1
          h2
          p
          a {
            href
            label
          }
          name
          institution
          bio
          role
          img
          fullwidth
          subtitle
          copy
          link
          list
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
