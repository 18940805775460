import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ description, keywords, title, image, url, author, twitterUsername, twitterImage }) => {
  return(
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description; 
        const metaTitle = title || data.site.siteMetadata.title;
        const metaUrl = url || data.site.siteMetadata.url;
        const metaImage = image || data.site.siteMetadata.image;
        const metaAuthor = author || data.site.siteMetadata.author;
        const metaTwitterUsername = twitterUsername || data.site.siteMetadata.twitterUsername;
        const metaTwitterImage = twitterImage || data.site.siteMetadata.twitterImage;
        const metaKeywords = keywords || ["Fred Hutch", "Brotman Baty", "genomics", "gene expression", "visualization", "atlas"]
        return (
          <Helmet
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              },
              {
                property: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: metaTwitterUsername,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaTwitterImage,
              },
              {
                name: `twitter:site`,
                content: metaTwitterUsername,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0 ? 
              {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
              : []
          )}
          />
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        description, 
        keywords, 
        title, 
        image, 
        url, 
        author, 
        twitterUsername,
        twitterImage
      }
    }
  }
`
