import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { Form, Formik } from "formik";
import React from "react";
// import Swal from "sweetalert2";
import swal from "sweetalert";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        backgroundColor: "#F4F4F7"
    },
    image: {
        backgroundImage: "url(../img/bg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "dark" ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(4, 24),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(4, 4),
        }
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontSize: "18px"
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        marginRight: "10px"
    },
    label: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "10px",
        fontSize: "16px"
    },
    error: {
        color: "red",
        fontSize: "12px",
        fontStyle: "italic",
        paddingLeft: "5px"
    }
}));

const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    organization: Yup.string()
        .required("Required")
});

interface Values {
    name: string;
    email: string;
    organization: string;
    additional: string;
}

interface Props {
    onSubmit: (values: Values) => void;
}

const FeatureRequestForm: React.FC<Props> = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            organization: "",
                            additional: ""
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            //   }, 400);
                            setTimeout(() => {
                                const subject = `Information Request From ${values.name}`;
                                const message = Object.keys(values).reduce((p, c) => {
                                    p += c + ": " + values[c] + "\n";
                                    return p;
                                }, "Info Request\n");
                                Axios({
                                    method: "post",
                                    url: "https://viz.fredhutch.org/dev/emailviz",
                                    data: JSON.stringify({ subject, message }),
                                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                                })
                                .then(() => {
                                    swal({
                                        title: "Success!",
                                        text: "Message Sent!",
                                        icon: "success",
                                    });
                                    resetForm();
                                }).catch(() => {
                                    swal({
                                        title: "Error",
                                        text: "Message unable to send!",
                                        icon: "warning",
                                        dangerMode: true,
                                    });
                                });
                            setSubmitting(false);
                        }, 50);

                    }}
                    >
                        {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                            <Form className={classes.form}>
                                <TextField
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    size="small"
                                    label="Name"
                                    margin="normal"
                                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                                    fullWidth
                                    required
                                />
                                {errors.name && touched.name ? (
                                    <div className={classes.error}>{errors.name}</div>
                                ) : null}
                                <TextField
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    size="small"
                                    label="Email Address"
                                    margin="normal"
                                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                                    fullWidth
                                    required
                                />
                                {errors.email && touched.email ? (
                                    <div className={classes.error}>{errors.email}</div>
                                ) : null}
                                <TextField
                                    name="organization"
                                    value={values.organization}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    size="small"
                                    label="Organization"
                                    margin="normal"
                                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                                    fullWidth
                                />
                                <TextField
                                    name="additional"
                                    value={values.additional}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    size="small"
                                    label="How can we help?"
                                    margin="normal"
                                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                                    fullWidth
                                    multiline
                                    rows="4"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="reset"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Reset
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Grid>
        </Grid >
    );
};
export default FeatureRequestForm;
