import { makeStyles, Theme, Typography, Grid, Button, GridList } from '@material-ui/core';
import * as React from 'react';
import H2 from '../layout/promo/H2';
import { fontSize } from '@material-ui/system';
import { Link, navigate } from 'gatsby';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        minWidth: 275,
        display: "inline-block",
        padding: theme.spacing(2),
        flexGrow: 1,
        height: "500px"
    },
    title: {
        color: "#112345",
        display: "inline-block",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "1px",
        textTransform: "uppercase",
        verticalAlign: "top",
        paddingBottom: "10px"
    },
    copy: {
        color: "#57646e",
        display: "inline-block",
        fontSize: "15px",
        verticalAlign: "top"
    },
    subtitle: {
        color: "#112345",
        display: "inline-block",
        fontSize: "15px",
        verticalAlign: "top",
        paddingBottom: "20px"
    }
}));

// Stateless Functional Component / Returns JSX
const FeatureCard: React.FC<any> = ({
    subtitle,
    title,
    copy,
    link,
    img,
    theme
}) => {
    const classes = useStyles(theme);
    return (
            <Grid
                item
                md={6}
                lg={3}
            >
                <Grid style={{ display: "inline-block", padding: "20px" }}>
                    <CardActionArea>
                        <Card className={classes.card}>
                            <a href={link} target="_blank">
                                <CardMedia
                                    component="img"
                                    alt="Card Image"
                                    height="120"
                                    image={img}
                                />
                                <CardContent>
                                    <Typography className={classes.title}>
                                        {title}
                                    </Typography>
                                    <Typography className={classes.subtitle}>
                                        {subtitle}
                                    </Typography>
                                    <Typography className={classes.copy}>
                                        {copy}
                                    </Typography>

                                </CardContent>
                            </a>
                        </Card>
                    </CardActionArea>
                </Grid>
            </Grid>
    );
};

export default FeatureCard;
