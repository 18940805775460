import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { fontWeight } from '@material-ui/system';

// Attributes You Can Set On Tag
interface H2IntroProps {
  title: string;
  path: string;
}

// Applied styles
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '3px'
  },
  imgTitle: {
    lineHeight: '20px',
    verticalAlign: 'top',
    display: 'inline-block',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 400,
    color: '#112345'
  },
  // img: {
  //   display: 'inline-block',
  //   height: '30px',
  //   marginRight: '10px'
  // }
}));

// Stateless Functional Component / Returns JSX
const H2Intro: React.FC<any> = ({ title, path, theme }) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      {/* <img className={classes.img} src={`/${path}`} /> */}
      <Typography className={classes.imgTitle}>{title}</Typography>
    </div>
  );
};

export default H2Intro;
