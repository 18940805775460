import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { TweenLite, CSSPlugin } from "gsap";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

const FeatureCarousel: React.FC<any> = ({ props, theme }) => {
  // Image Tags
  const imagesRef = new Array<HTMLImageElement>();
  const plugins = [CSSPlugin];
  const images = props.data.map((datum, i) => {
    return (
      <img
        key={i}
        src={datum.image}
        alt={datum.title}
        ref={(el) => {
          if (el) {
            imagesRef.push(el);
          }
        }}
        style={{
          width: `${props.imageSize[0]}px`,
          height: `${props.imageSize[1]}px`,
          position: "absolute",
        }}
      />
    );
  });

  // Copy Tags
  const labelsRef = new Array<HTMLDivElement>();
  const labels = props.data.map((datum, i) => {
    return (
      <div
        key={i}
        ref={(el) => {
          if (el) {
            labelsRef.push(el);
          }
        }}
        style={{
          width: "100%",
          position: "absolute",
          top: "0px",
          padding: "0px 60px 0px 30px",
          color: "#6b6b76",
        }}
      >
        <h3 className="title">{datum.title}</h3>
        <p className="info">{datum.info}</p>
      </div>
    );
  });

  // Looping Index Helper
  const getIndexOf = (dir: "NEXT" | "PREV") => {
    const newIndex = index + (dir === "NEXT" ? 1 : -1);
    if (newIndex >= images.length) {
      return 0;
    }
    if (newIndex < 0) {
      return images.length - 1;
    }
    return newIndex;
  };

  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    // Animate Images In
    if (imagesRef.length === 0) {
      return;
    }

    TweenLite.fromTo(
      imagesRef[index],
      0.6,
      { x: 300, opacity: 0 },
      { x: 0, opacity: 1 }
    );
    // Animate Images Out
    TweenLite.to(
      imagesRef.filter((v, i) => i !== index),
      { x: -50, opacity: 0 }
    );
    // Animate Labels In
    TweenLite.fromTo(
      labelsRef[index],
      0.6,
      { y: -30, x: 0, opacity: 0 },
      { y: 0, opacity: 1 }
    );
    // Animate Labels Out
    TweenLite.to(
      labelsRef.filter((v, i) => i !== index),
      0.6,
      { opacity: 0 }
    );
  }, [index]);

  return (
    <Grid title={props.title}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: `${props.imageSize[1]}px`,
          color: "#6b6b76",
        }}
      >
        <div
          style={{
            left: "36px",
            width: `${props.imageSize[0]}px`,
            height: `${props.imageSize[1]}px`,
            overflow: "hidden",
            position: "absolute",
          }}
        >
          {images}
        </div>
        <div
          style={{
            height: `${props.imageSize[1]}px`,
            left: `${props.imageSize[0] + 36}px`,
            right: "36px",
            overflow: "hidden",
            position: "absolute",
          }}
        >
          {labels}
        </div>
        <IconButton
          onClick={() => setIndex(getIndexOf("PREV"))}
          style={{
            position: "absolute",
            left: "0px",
            top: `${props.imageSize[1] * 0.5 - 48}px`,
          }}
        >
          <ArrowBackIos />
        </IconButton>

        <IconButton
          onClick={() => setIndex(getIndexOf("NEXT"))}
          style={{
            position: "absolute",
            right: "0px",
            top: `${props.imageSize[1] * 0.5 - 48}px`,
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </Grid>
  );
};

export default FeatureCarousel;
