import { Drawer, makeStyles, Slide, useScrollTrigger } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import * as React from 'react';
import { height, textAlign } from '@material-ui/system';

const HideOnScroll: React.FC<any> = props => {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 1300,
    height: "80px"
  },
  logo: {
    marginLeft: '0px',
    height: '45px',
    marginTop: '20px',
    [theme.breakpoints.down("md")]: {
      height: '30px'
    }
  },
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(6)
  },
  title: {
    flexGrow: 1
  },
  drawer: {
    backgroundColor: theme.palette.primary.dark,
    padding: '12px',
    height: '100vh'
  },
  drawerItems: {
    position: 'absolute',
    top: '120px',
    right: '20px',
    textAlign: 'right'
  },
  drawerLink: {
    fontSize: '12px',
    letterSpacing: '1.2px',
    color: '#fff',
    textDecoration: 'none',
    padding: '12px',
    textAlign: 'left',
    display: 'inline-block',
    textTransform: 'uppercase',
    '&:visited': {
      color: '#FFF'
    },
    '&:hover': {
      color: '#FFF',
      textDecoration: 'underline'
    }
  },
  hamButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    fontSize: '12px',
    letterSpacing: '1.2px',
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0px 12px',
    '&:visited': {
      color: '#FFF'
    },
    '&:hover': {
      color: '#FFF',
      textDecoration: 'underline'
    }
  },
  navButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    paddingLeft: '36px',
    fontSize: '12px',
    letterSpacing: '1.2px',
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0px 0px',
    '&:visited': {
      color: '#FFF'
    },
    '&:hover': {
      color: '#FFF',
      textDecoration: 'underline'
    }
  }
}));

interface IProps {
  logo: string;
  config: any;
}

const AppbarMini: React.FC<IProps> = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => setOpen(false);
  return (
    <div>
      {/* <HideOnScroll> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <a href="https://viz.fredhutch.org/">
            <img
              className={classes.logo}
              src="/img/logos/dvc-logo-teal.png"
              alt="logo"
            />
          </a>
          <Typography variant="h6" noWrap className={classes.title} />
          <Button className={classes.hamButton} onClick={() => setOpen(!open)}>
            menu
          </Button>
          <span>
            {props.config.navigation.map((v: any, i: number) => {
              return (
                <AniLink
                  className={classes.navButton}
                  key={i}
                  fade
                  direction="up"
                  duration={0.9}
                  bg="#017e80"
                  to={v.link}
                >
                  {v.label}
                </AniLink>
              );
            })}
          </span>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <Drawer variant="persistent" anchor="top" open={open}>
        {/* <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ padding: '0px' }}>
           
          </Toolbar>
        </AppBar> */}
        <div className={classes.drawer}>
          <div className={classes.drawerItems}>
            {props.config.navigation.map((v: any, i: number) => {
              return (
                <div key={i}>
                  <AniLink
                    className={classes.drawerLink}
                    fade
                    direction="up"
                    duration={0.9}
                    bg="#017e80"
                    to={v.link}
                  >
                    {v.label}
                  </AniLink>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AppbarMini;
