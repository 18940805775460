import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';
import { lineHeight, display } from '@material-ui/system';

// background: '#2c3e50',
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#112345',
    padding: theme.spacing(6),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4)
  },
  logo: {
    height: '45px',
    float: 'left',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginBottom: theme.spacing(2)
    }
  },
  primaryLink: {
    cursor: 'pointer',
    fontSize: '16px',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    textDecoration: 'none',
    lineHeight: '30px'
  },
  secondaryLink: {
    display: 'block',
    cursor: 'pointer',
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    paddingLeft: theme.spacing(1),
    lineHeight: '30px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingLeft: theme.spacing(0)
    }
  },
  copywrite: {
    lineHeight: '34px',
    verticalAlign: 'middle',
    float: 'left',
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      float: 'none'
    }
  },
  primaryDiv: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  secondaryDiv: {
    border: '0px solid rgba(255,255,255, .2)',
    borderTopWidth: '1px',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  icon: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center'
    }
  }
}));

interface IProps {
  logo: string;
}

const Footer: React.FC<any> = ({ theme }) => {
  const classes = useStyles(theme);
  return (
    <footer className={classes.container}>
      <div className={classes.primaryDiv}>
        <a
          className={classes.secondaryLink}
          href="https://www.fredhutch.org/en/util/terms-privacy.html"
        >
          Terms of Use &amp; Privacy Policy
        </a>
        <a
          className={classes.secondaryLink}
          href="https://www.fredhutch.org/en/about/about-the-hutch/accountability-impact.html"
        >
          Accountability & Impact
        </a>
        <a
          className={classes.secondaryLink}
          href="https://www.fredhutch.org/en/util/conflict-of-interest.html"
        >
          Conflict of Interest
        </a>
        <a
          className={classes.secondaryLink}
          href="https://viz.fredhutch.org/contact/"
        >
          Contact Us
          </a>
      </div>
      <div className={classes.secondaryDiv}>
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <div>
            <img className={classes.logo} src="/img/logos/dvc-logo-teal.png" />
          </div>
          <a
            className={'sprites twitter ' + classes.icon}
            href="https://twitter.com/FHDataViz"
            target="_blank"
          />
          <a
            className={'sprites linkedin ' + classes.icon}
            href="https://www.linkedin.com/company/fhdataviz"
            target="_blank"
          />
          <a
            className={'sprites instagram ' + classes.icon}
            href="https://www.instagram.com/fhdataviz/"
            target="_blank"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
