import { Box, Button, Grid, GridList, makeStyles, Theme, Paper, Typography, Link } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Container from '@material-ui/core/Container';
import FolderIcon from '@material-ui/icons/Folder';
import FeatureCopy from "../feature/FeatureCopy";

const useStyles = makeStyles((theme: Theme) => ({
    copyH1: {
        color: "#112345",
        fontSize: "23px",
        lineHeight: "25px",
        verticalAlign: "top",
        marginBottom: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px"
        }
    },
    copyP: {
        marginBottom: "20px",
        fontSize: "15px",
        color: "#57646e",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px"
        }
    },
    img: {
        width: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "15px",
        }
    },
    copyCell: {
        padding: "60px 100px 30px 100px",
        // padding: 60,
        background: "#FFF",
        // background: "##F4F4F7",
        [theme.breakpoints.down("sm")]: {
            padding: 30
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: 60
        },
    },
    listItem: {
        '& span, & svg, & hover': {
            fontSize: "15px",
            color: "#112345",
            paddingLeft: 0
        }
    },
    listItem2: {
        '& span, & svg, & hover': {
            fontSize: "12px",
            color: "#57646e",
            textTransfrom: "uppercase"
        }
    }
}));

const FeatureBullet: React.FC<any> = ({
    copy,
    theme,
    title,
    img,
    list,
    link
}) => {
    const classes = useStyles(theme);
    return (
        <Grid
            container
            direction="row"
        >
            <Grid className={classes.copyCell} item xs={12} md={6}>
                <Typography gutterBottom className={classes.copyH1}>
                    {title}
                </Typography>
                <Grid >
                    <Typography className={classes.copyP}>
                        {copy}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.copyCell} item xs={12} md={6}>
                {/* <Typography gutterBottom className={classes.copyH1}>
                    Past Publications
                    </Typography> */}
                <List className={classes.copyP} >
                    <Grid container direction="row" item xs={12} >
                        <Grid item xs={12} md={12} >
                            <ListItem>
                                {/* <ListItemIcon className={classes.listItem}>
                                    <img className={classes.img} src={img} alt="" />
                                </ListItemIcon> */}
                                {/* <List>
                                    <ListItem button component="a" href={link}>
                                        <ListItemText className={classes.listItem} primary={list} />
                                    </ListItem>
                                </List> */}
                                <List>
                                    <ListItem className={classes.listItem} component="a" href="https://www.nature.com/articles/s41586-019-0969-x" target="_blank">
                                        <List>
                                            <ListItemText className={classes.listItem2} primary="Nature - February 20, 2019" />
                                            <ListItemText className={classes.listItem} primary="The single-cell transcriptional landscape of mammalian organogenesis" />
                                        </List>
                                    </ListItem>
                                    <ListItem component="a" href="https://www.nature.com/articles/s41588-018-0208-7" target="_blank">
                                        <List>
                                            <ListItemText className={classes.listItem2} primary="Nature Genetics - August 29, 2018" />
                                            <ListItemText className={classes.listItem} primary="Analysis and visualization of linked molecular and clinical cancer data by using Oncoscape" />
                                        </List>
                                    </ListItem>
                                    <ListItem component="a" href="https://www.ncbi.nlm.nih.gov/pubmed/28532485" target="_blank">
                                        <List>
                                            <ListItemText className={classes.listItem2} primary="Acta Neuropathologica Communications - May 22, 2017" />
                                            <ListItemText className={classes.listItem} primary="Multidimensional scaling of diffuse gliomas" />
                                        </List>
                                    </ListItem>
                                </List>
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Grid>
        </Grid>
    );
};

export default FeatureBullet;
