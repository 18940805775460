import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import Helmet from 'react-helmet';
import AppbarMini from './AppbarMini';
import Footer from './Footer';
import { Seo } from "./Seo";
import useSiteMetadata from './SiteMetadata';
import theme from './theme';

interface IProps {
  children: any;
  config: any;
}

const Layout: React.FC<IProps> = props => {
  const { title, description } = useSiteMetadata();
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,600&display=swap"
            rel="stylesheet"
          />
          <meta name="theme-color" content="#ffffff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content="//viz.fredhutch.org/img/og.jpg"
          />
          <meta
            property="og:description"
            content="Translating data into discoveries"
          />
          <meta property="og:url" content="//viz.fredhutch.org" />
          <link rel="stylesheet" type="text/css" href="./css/sprite.css" />
        </Helmet>
        <Seo
        // keywords={"keywords"}
        //description={}
        // title="title"
        // image="image"
        // url="url"
        // author="author"
        // twitterUsername="twitterUsername"
        />
        <CssBaseline />
        <AppbarMini logo="test" config={props.config} />
        <div>{props.children}</div>
        <Footer logo="test" />
      </div>
    </MuiThemeProvider>
  );
};

export default Layout;
