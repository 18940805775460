import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    // padding: '70px',
    padding: '30px',
    backgroundColor: theme.palette.text.primary,
    background: 'linear-gradient(to right,#112345,#0d1a33)',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },

  copy: {
    fontSize: '30px',
    padding: '30px',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize
    },
  }
}));

// Stateless Functional Component / Returns JSX
const FeatureQuote: React.FC<any> = ({ quote, theme }) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography variant="body1" component="h1" className={classes.copy}>
        {quote}
      </Typography>
    </div>
  );
};

export default FeatureQuote;
