import { makeStyles, Theme, Typography, Grid, Button } from '@material-ui/core';
import * as React from 'react';
import H2 from '../../components/layout/promo/H2';
import { fontSize } from '@material-ui/system';
import { Link, navigate } from 'gatsby';

const useStyles = makeStyles((theme: Theme) => ({
  copyCell: {
    padding: 60,
    background: '#F4F4F7',
    [theme.breakpoints.down('sm')]: {
      padding: 30
    }
  },
  copyH1: {
    fontWeight: 700
  },
  copyH2: {
    marginBottom: '10px',
    fontSize: '16px',
    color: '#112345',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize
    }
  },
  copyP: {
    marginBottom: '20px',
    fontSize: theme.typography.body2.fontSize,
    color: "#57646e",
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  copyL: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}));

// Stateless Functional Component / Returns JSX
const FeatureCopy: React.FC<any> = ({
  h1,
  h2,
  p,
  a,
  // img,
  fullwidth,
  theme
}) => {
  const classes = useStyles(theme);

  const elH2 = // If H2 Or P Has A \\n In it then it will break into paragraphs - That's why it's a map
    h2 === null
      ? []
      : h2.split('\\n').map((copy: string, i: number) => (
          <Typography key={`h2${i}`} className={classes.copyH2}>
            {copy}
          </Typography>
        ));
  const elP =
    p === null
      ? []
      : p.split('\\n').map((copy: string, i: number) => (
          <Typography key={`h2${i}`} className={classes.copyP}>
            {copy}
          </Typography>
        ));

  // Default Anchor To Nothing
  let elA;
  if (a !== null) {
    if (a.href.toString().startsWith('http')) {
      elA = (
        <Button variant="outlined" size="small">
          <a className={classes.copyL} href={a.href}>
            {a.label}
          </a>
        </Button>
      );
    } else {
      elA = (
        <Button variant="outlined" size="small">
          <Link className={classes.copyL} to={a.href}>
            {a.label}
          </Link>
        </Button>
      );
    }
  }
  if (elA === null) {
    elA = [];
  }

  // if (img === null) {
  //   img = './img/logos/logo-dvc.png';
  // }
  const md = fullwidth ? 12 : 6;
  return (
    <Grid item xs={12} md={md} className={classes.copyCell}>
      {/* <H2 path={img} title={h1} /> */}
      <H2 title={h1} />
      {elH2}
      {elP}
      {elA}
    </Grid>
  );
};

export default FeatureCopy;
