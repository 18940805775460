
import { makeStyles, Theme, Typography, Grid, Button, GridList } from '@material-ui/core';
import * as React from 'react';
import H2 from '../../components/layout/promo/H2';
import { fontSize } from '@material-ui/system';
import { Link, navigate } from 'gatsby';
import InstagramEmbed from 'react-instagram-embed';
import {
    TwitterTimelineEmbed,
    TwitterShareButton,
    TwitterFollowButton,
    TwitterHashtagButton,
    TwitterMentionButton,
    TwitterTweetEmbed,
    TwitterMomentShare,
    TwitterDMButton,
    TwitterVideoEmbed,
    TwitterOnAirButton
}
    from 'react-twitter-embed';

const useStyles = makeStyles((theme: Theme) => ({
    twitter: {
        backgroundColor: "#ffffff",
        padding: "60px"

    }
}));

const FeatureTwitter: React.FC<any> = ({
    theme
}) => {
    const classes = useStyles(theme);
    return (
        <Grid item xs={12} md={6} lg={6} className={classes.twitter}>
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="FHDataViz"
                options={{ height: 400 }}
                noFooter
            />

      </Grid>
    );
};

export default FeatureTwitter;

